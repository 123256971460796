import React from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import backgroundImg from '../resources/images/background.jpg';
import logoImg from '../resources/images/logo-main.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(2),
    width: '100%',
    boxShadow: '0 0 15px rgba(0,0,0,.5)',
    backgroundColor: 'white',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
      </div>
      <Paper className={classes.paper}>
        <div style={{ color: 'white', padding: 10, fontSize: '2rem' }}>Zona e Klientit</div>
        <form className={classes.form}>
          <div style={{ padding: 10, display: 'flex', justifyContent: 'center', marginBottom: 20 }}><img src={logoImg} alt="Logo" /></div>
          {children}
        </form>
        <div style={{ color: 'white', padding: 10, fontSize: '14px', marginTop: 20 }}>Platforma e Gjurmimit GPS IGTrack - Mbështetja e Klientit</div>
      </Paper>
    </main>
  );
};

export default LoginLayout;
